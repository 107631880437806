import styled from 'styled-components'

export const Em = styled.span`
  color: ${({ theme }) => theme.palette.text['0']};
`

export const Uem = styled.span`
  text-decoration: underline;
`

export const Hem = styled.span`
  background-color: #e87d7d59;
  //background-color: #b7dcc1ad;
  // background-color: ${({ theme }) => theme.palette.green['light0']};
  color: black;
  padding-left: 0.0625em;
  padding-right: 0.0625em;
  z-index: 0;
`
