import React from 'react'
import Box from '@local/shared/components/System/Box'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const SavvasProfilePic = () => {
  const data = useStaticQuery(graphql`
    query {
      savvasProfile: file(relativePath: { eq: "savvas-profile.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box
      borderRadius={'50%'}
      css={{ overflow: 'hidden' }}
      border={'solid 0.25em'}
      borderColor={'blue.0'}
      mb={'1.25em'}
    >
      <Img
        fluid={data.savvasProfile.childImageSharp.fluid}
        objectFit={'cover'}
      />
    </Box>
  )
}

export default SavvasProfilePic
